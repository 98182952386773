'use strict';
var EMA = EMA || {};

EMA.BUILD = (function() {
    return {
        init: function() {
            // Find all controller in page
            // Define template or content
            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // Convert IMG to SVG source code inline
            this.svg('img.svg-convert');

            // Load Lazy Images with animation
            this.lazyLoader();

            $('.reveal-button-yes').click(function(e){
                e.preventDefault();
                $.cookie('ageVerification', 'valid',{ path: '/' })
                $('#warning-age-modal').foundation('close');
            })
            if(!$.cookie('ageVerification')) {
                $('#warning-age-modal').foundation('open');
            }
        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: 0,
                success: function(element) {

                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 10);
                }
            });

            return bLazy;
        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        }
    };
})();

EMA.HEADER = (function() {
    return {
        init: function(elem) {
            var self = this;

            self.resize(elem);

            // $(window).resize(function() {
            //     self.resize(elem);
            // });
            $(window).resize(function() {
                self.resize(elem);
            });

            var paused = true;
            var vid = document.getElementById("header-video"); 

            function playVid() { 
                vid.play(); 
                $('.video-controls-container .icon-icon-play').removeClass("icon-icon-play" ).addClass("icon-icon-pause");
                hideControls();
            } 

            function pauseVid() { 
                vid.pause(); 
                $('.video-controls-container .icon-icon-pause').removeClass("icon-icon-pause" ).addClass("icon-icon-play");
            }
            function hideControls()
            {
                //$('.video-controls-container').delay(delay).fadeOut(1000);
                var timer;
                $(document).mousemove(function() {
                    if (timer) {
                        clearTimeout(timer);
                        timer = 0;
                    }

                    $('.video-controls-container').fadeIn();
                    $('.video-fit-fullscreen a.icon-icon-scroll-down').fadeIn();
                    timer = setTimeout(function() {
                        $('.video-controls-container').fadeOut();
                        $('.video-fit-fullscreen a.icon-icon-scroll-down').fadeOut();
                    }, 1500)
                })
            }
            var vidHasPLayed = false;

            $("#header-video").bind("ended", function() {
                vid.play();
                
                if(!vidHasPLayed && $(document).scrollTop() == 0) {
                    $('body,html').animate({
                        scrollTop: $(".generic-content-container").offset().top
                    }, 1000, "swing");
                    vidHasPLayed = true;
                }
            });
            $('.video-fit-fullscreen a.icon-icon-scroll-down').click(function(){
                vidHasPLayed = true;
                $('body,html').animate({
                        scrollTop: $(".generic-content-container").offset().top
                }, 1000, "swing");
            })
            $('.icon-icon-play').bind("click", function(){
                //console.log(paused);
                
                if (paused) {
                    paused = false;
                    playVid();
                } else {
                    pauseVid();
                    
                    paused = true;
                }
            })



        },
        resize: function(elem) {

            // var headerPaddings = parseInt($(elem).css("padding-top")) + parseInt($(elem).css("padding-bottom"));
            // console.log(headerPaddings);
            // //width*1080/1920

            // var videos = $(elem).data(videos).videos;
            // var rand = Math.floor(Math.random() * videos.length);
            // var videoURL = "http://player.vimeo.com/video/" + videos[rand] + "?loop=1&background=1&muted=1&title=0&autoplay=1&byline=0&controls=0";
            
            // $(elem).height(($(window).outerHeight()));
            //console.log(Math.round($(window).height()));
            
           // $('#header .video-fit-fullscreen video').height(Math.round($(window).height()));
            $('#header .video-fit-fullscreen video').parent().attr('height', $(window).height()*9/16);

            var fixedBackToTopRight = ((Math.round($(window).width())-$(".generic-content-container .container .row:first-child").width())/2)-60;
            //console.log(fixedBackToTopRight);
            $('.fixed-back-to-top').css('right', fixedBackToTopRight+"px");
            // console.log($('#header .header-image').outerHeight());
            // $('#header .header-image img').width($(window).width());
            // $('#header .header-image').width($(window).width());
            // if($(window).height() >= $(window).width()) {
            //     if($('.header-image').length) {
            //         var newWidth = Math.round($(window).height() * $('#header .header-image img').data('owidth') / $('#header .header-image img').data('oheight'));

            //         $('#header .header-image img').height($(window).height());
            //         $('#header .header-image').height($(window).height()); 

            //         $('#header .header-image img').width(newWidth);
            //         $('#header .header-image').width(newWidth);   
            //         console.log(newWidth);

            //     } 
            // }

        }
    };
})();

EMA.NAV = (function() {
    return {
        init: function(elem) {
            $(elem).on('click', '.hamburger', function(event) {
                event.preventDefault();
                $(event.delegateTarget).toggleClass('is-active');
            });
            $(document).ready(function() {
                $('#language li a.languageItem, .reveal a.lang-switch').click(function() {
                    setLanguageCookie($(this).data('content-id'), $(this).attr('data-language-id'));
                });
            })



            $(window).scroll(function (event) {
                
                var dif = 50;
                if($(elem).hasClass('is-waiting-nav')) {

                    dif = $('.main-color-bg.padd').height()+100;
                    
                    if($(window).scrollTop() > dif) {
                        $('.nav-menu, #header h1.logo').addClass("dark");
                    }
                    else {
                        $('.nav-menu, #header h1.logo').removeClass("dark");
                    }
                } else {

                    if(!$(elem).hasClass('is-contacts-nav')) {
                        if($(window).scrollTop() > $(window).height() - dif) {
                            $('.nav-menu, #header h1.logo').addClass("dark");
                        }
                        else {
                            $('.nav-menu, #header h1.logo').removeClass("dark");
                        }

                        if($('.technical-charts-container').length) {
                            if(($(window).scrollTop() > $('.technical-charts-container').offset().top-dif)) {
                                $('.nav-menu, #header h1.logo').removeClass("dark");
                                // $('.fixed-back-to-top').addClass("light");
                            }
                        }
                    } 
                }
                if($(window).scrollTop() > $('#header').height()*2) {
                    $('.fixed-back-to-top').fadeIn();
                }
                if($(window).scrollTop() < $('#header').height()*2) {
                    $('.fixed-back-to-top').fadeOut();
                }

                // Do something
            });
        }
    };
})();

EMA.FOOTER = (function() {
    return {
        init: function(elem) {

            var self = this;

            self.resize(elem);

            $(window).resize(function() {
                self.resize(elem);
            });
        },
        resize: function(elem) {
            var footer = $(elem);
            var height = footer.height();
            var paddingTop = parseInt(footer.css('padding-top'), 10); //zero if is undefined
            var paddingBottom = parseInt(footer.css('padding-bottom'), 10); //zero if is undefined
            var totalHeight = (height + paddingTop + paddingBottom);
            var footerPosition = footer.position();
            var windowHeight = $(window).height();
            height = (windowHeight - footerPosition.top) - totalHeight;
            if (height > 0) {
                footer.css({
                    'margin-top': (height) + 'px'
                });
            }
        }
    };
})();

EMA.GOTOP = (function() {
    return {
        init: function(elem) {

            var self = this;

            $(elem).on('click', function() {
                $('body,html').animate({
                    scrollTop: 0
                }, 800);

                return false;
            });

        }
    };
})();

EMA.WAITING = (function() {
    return {
        init: function() {

            $("#privacy-btn").on('click', function() {

                $('body,html').animate({
                    scrollTop: $("#pagesTab").offset().top - 40
                }, 800);

                return false;
            });
        }
    }
})

EMA.FORM = (function() {
    return {
        init: function(elem) {

            /*
                HOW USE FORM
                https://foundation.zurb.com/sites/docs/abide.html
            */

            $(elem)
                .on('formvalid.zf.abide', function(event, form) {
                    $(this).find('.submit').fadeOut();

                    $.post('/frontend/engine/engine.php', {
                        'action': 'sendEmailByObject',
                        'body': form.serializeArray()
                    }, function(data, status, xhr) {
                        if (data) {

                            $(elem).find('.msg').text(data.label).addClass('show');

                            if (data.kind == "success") {
                                console.log(data);
                            } else {
                                $(this).find('.submit').fadeIn();
                            }

                        } else {
                            console.error(status, xhr);
                        }
                    }, 'json').fail(function(xhr, textStatus, errorThrown) {
                        console.error(xhr, textStatus, errorThrown);
                    });

                })
                .on('submit', function(event) {
                    event.preventDefault();
                });

        }
    };
})();

EMA.WINE =(function(){
    return {
        init: function(elem) {

            var self = this;

            var year;
            if(window.location.hash) {
              // Fragment exists
              year = parseInt(window.location.href.split('#')[1]);
            }
            else {
                year = $('.tabs').find(".is-active").find('a').data("tabs-target");
            }

            self.changeBottle(year);
            $('.tabs li a').on('click', function(ev) {
                var year = $(this).data("tabs-target");
                self.changeBottle(year);
            });
            
            
        },
        changeBottle: function(year) {

            $('.bottles-container').find(".bottle").removeClass("is-active");
            $('.bottles-container').find("#"+year+"-bottle").addClass("is-active").delay(1000);
        }
        
    }
})
EMA.RIVER =(function(){
    return {
        init: function(elem) {

            var self = this;

            // var vid = document.getElementById("header-video");
            // var vidHasPLayed = false;

            // $("#header-video").bind("ended", function() {
            //     vid.play();
                
            //     if(!vidHasPLayed && $(document).scrollTop() == 0) {
            //         $('body,html').animate({
            //             scrollTop: $(".river").offset().top
            //         }, 1000, "swing");
            //         vidHasPLayed = true;
            //     }
            // });
        }
    }
})
EMA.FAMILY =(function(){
    return {
        init: function(elem) {

            var self = this;

            // var vid = document.getElementById("header-video");
            // var vidHasPLayed = false;

            // $("#header-video").bind("ended", function() {
            //     vid.play();
                
            //     if(!vidHasPLayed && $(document).scrollTop() == 0) {
            //         $('body,html').animate({
            //             scrollTop: $(".family").offset().top
            //         }, 1000, "swing");
            //         vidHasPLayed = true;
            //     }
            // });
        }
    }
})

$(function() {
    // Foudation
    $(document).foundation();

    // Call when document is ready
    EMA.BUILD.init();

    $('.zoom-animate').addClass('animatable');

    setTimeout(function() {
        $('.zoom-animate').removeClass('animatable');
    }, 4000)

    $('.parallax').parallaxElement({
      defaultSpeed:  0.08,   // Integer - Default speed if `data-speed` is not present
      useOffset:     true,  // Boolean - Whether or not to start animations below bottom of viewport
      defaultOffset: 100,   // Distance before element appears to start animation
      disableMobile: false, // Boolean - allow function to run on mobile devices
      minWidth:      false  // Integer - minimum width the function should fire
    });

    var animation = function() {

        var elems,
            windowHeight;

        var init = function() {
            elems = document.getElementsByClassName('animation-do')
            windowHeight = window.innerHeight
            _addEventHandlers()
        }

        var _addEventHandlers = function() {
            window.addEventListener('scroll', _checkPosition)
            window.addEventListener('resize', init)
        }

        var _checkPosition = function() {
            for (var i = 0; i < elems.length; i++) {
                var posFromTop = elems[i].getBoundingClientRect().top
                if (posFromTop - windowHeight <= 0) {
                    elems[i].className = elems[i].className.replace('animation-do', 'element-animate')
                }
            }
        }

        return {
            init: init
        }
    }

    animation().init()

});



